var lastaccessfile='';
/***
  判断  $str 中是否存在$str1
  $strfirst='nihao hellow';
  $strsecond='nihao';
  str_exists($strfirst,$strsecond); // 返回值true
 * @param {要做判断的字符串} strfirst
 * @param {用来做判断的字符串} strsecond
 * **/ 
function str_exists(strfirst,strsecond){
  let ifexiststr = strfirst.indexOf(strsecond);
  if(ifexiststr==-1){
    return false;
  }else{
    return true;
  }
}
function autoload_index_route(router_type,path, name, meta, children, redirect){
  let myfiles=window.location.pathname;
  let ifpathexists_url=str_exists(myfiles,'/'+router_type+'/');
  if(ifpathexists_url){
    path=myfiles;
  }else{
    return false;
  }
  meta={ title: 'auto_loading_files', requireAuth: false };
  return {
      exact: true,
      path,
      name,
      meta,
      children,
      redirect,
      component: () => import(`@/views/theme/default${myfiles}.vue`)
    }
}

/**
 *
 * @param {路径} path
 * @param {组件路径} file
 * @param {别名，没有传null } name
 * @param {说明，没有就传{} } meta
 * @param {子级，没有子级可以不传} children
 */
 function route(path, file, name, meta, children, redirect) {
  // return routemyurl(path,file,name,meta,children,redirect);
  
  return {
    exact: true,
    path,
    name,
    meta,
    children,
    redirect,
    component:  () => import(`@/${file}`)
  }
}
const indexRouterlist = [
  route('/', 'views/theme/default/yahoojp/Home', '', { title: '首页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/Home', 'views/theme/default/yahoojp/Home', '', { title: '雅虎首页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/Rakutener', 'views/theme/default/rakuten_old/Rakutener', 'Rakutener', { title: '乐天中古', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/RakutenerGoodsList', 'views/theme/default/rakuten_old/RakutenerGoodsList', 'RakutenerGoodsList', { title: '乐天中古列表', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/RakutenerGoodDetail', 'views/theme/default/rakuten_old/RakutenerGoodDetail', 'RakutenerGoodDetail', { title: '乐天中古详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/RakutenerSeller', 'views/theme/default/rakuten_old/RakutenerSeller', 'RakutenerSeller', { title: '乐天中古卖家', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/downloadApp', 'views/theme/default/home/download', 'downloadApp', { title: '下载APP', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/goodsList', 'views/theme/default/yahoojp/goodsList', 'goodsList', { title: '雅虎商品列表', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/goodDetail', 'views/theme/default/yahoojp/goodDetail', 'goodDetail', { title: '雅虎商品详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/meiluHome', 'views/theme/default/mercari/meiluHome', 'meiluHome', { title: '煤炉首页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/meiluGoodsList', 'views/theme/default/mercari/meiluGoodsList', 'meiluGoodsList', { title: '煤炉商品列表', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/meiluGoodDetail', 'views/theme/default/mercari/meiluGoodDetail', 'meiluGoodDetail', { title: '煤炉详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/meiluSeller', 'views/theme/default/mercari/meiluSeller', 'meiluSeller', { title: '煤炉卖家列表', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/order-pack-pay', 'views/theme/default/Orders/OrderpackPay', 'OrderpackPay', { title: '订单打包', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/for-pack-pay', 'views/theme/default/Orders/ForPackPay', 'ForPackPay', { title: '支付国际运费', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/cart', 'views/theme/default/shop/Cart', 'Cart', { title: '购物车', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/checkout-alternative', 'views/theme/default/shop/CheckoutAlternative', 'CheckoutAlternative', { title: '提交订单', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/checkout-alternative-self', 'views/theme/default/shop/CheckoutAlternativeSelf', 'CheckoutAlternativeSelf', { title: '提交订单', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/order-pay', 'views/theme/default/Orders/orederPay', 'orederPay', { title: '支付订单', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/order-pay-self', 'views/theme/default/Orders/orederPaySelf', 'orederPaySelf', { title: '支付订单', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/articleItem', 'views/theme/default/home/articleItem', 'articleItem', { title: '文章列表', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/article-list', 'views/theme/default/home/articleList', 'article-list', { title: '帮助中心', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/advance-order', 'views/theme/default/home/advanceorder', 'advance-order', { title: '预报下单', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/AUCNET', 'views/theme/default/channel/AUCNET/list', 'AUCNET', { title: 'auc会场列表', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/AUCNET-detail', 'views/theme/default/channel/AUCNET/detail', 'AUCNET-detail', { title: 'auc会场详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/AUCNET-Tuesday', 'views/theme/default/channel/AUCNET/list-tuesday', 'AUCNET-Tuesday', { title: 'auc综合会场', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/AUCNET-Tuesday-detail', 'views/theme/default/channel/AUCNET/detail-tuesday', '', { title: 'auc综合会场详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/paypay', 'views/theme/default/paypay/paypay', '', { title: 'paypay', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/paypayGoodsList', 'views/theme/default/paypay/paypayGoodsList', '', { title: 'paypay列表', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/paypayGoodDetail', 'views/theme/default/paypay/paypayGoodDetail', '', { title: 'paypay详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/paypaySeller', 'views/theme/default/paypay/paypaySeller', 'paypaySeller', { title: 'paypay卖家', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/Rakuten', 'views/theme/default/Rakuten/Rakuten', '', { title: '乐天新品', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/rakutenGoodsList', 'views/theme/default/Rakuten/rakutenGoodsList', '', { title: '乐天新品列表', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/rakutenGoodDetail', 'views/theme/default/Rakuten/rakutenGoodDetail', '', { title: '乐天新品详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/rakutenSeller', 'views/theme/default/Rakuten/rakutenSeller', 'rakutenSeller', { title: '乐天新品卖家', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/zozotown', 'views/theme/default/zozotown/zozotown', 'zozotown', { title: 'zozotown', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/zozoGoodsList', 'views/theme/default/zozotown/zozoGoodsList', 'zozoGoodsList', { title: 'zozotown列表', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/zozoGoodDetail', 'views/theme/default/zozotown/zozoGoodDetail', 'zozoGoodDetail', { title: 'zozotown详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/zozoSeller', 'views/theme/default/zozotown/zozoSeller', 'zozoSeller', { title: 'zozotown详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/fill-shop', 'views/theme/default/fillshop/index', '', { title: '填单购物', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/calculator', 'views/theme/default/home/calculator', '', { title: '到手价估算', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/yahooShop', 'views/theme/default/yahooShop/yahooShop', 'yahooShop', { title: '雅虎直购', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/yahooShopList', 'views/theme/default/yahooShop/yahooShopList', 'yahooShopList', { title: '雅虎直购列表', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/yahooShopGoodDetail', 'views/theme/default/yahooShop/yahooShopGoodDetail', '', { title: '雅虎直购详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/yahooShopSeller', 'views/theme/default/yahooShop/yahooShopSeller', 'yahooShopSeller', { title: '雅虎直购卖家', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/amazon', 'views/theme/default/amazon/amazon', '', { title: '亚马逊首页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/amazonList', 'views/theme/default/amazon/amazonList', '', { title: '亚马逊列表页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/amazonGoodDetail', 'views/theme/default/amazon/amazonGoodDetail', 'amazon', { title: '亚马逊详情页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/mikihouse', 'views/theme/default/mikihouse/mikihouse', '', { title: 'Mikihouse首页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/mikihouseList', 'views/theme/default/mikihouse/mikihouseList', '', { title: 'Mikihouse列表页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/mikihouseDetails', 'views/theme/default/mikihouse/mikihouseDetails', 'mikihouse', { title: 'Mikihouse详情页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),

  route('/Suruga', 'views/theme/default/Suruga/Suruga', '', { title: '骏河屋首页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  route('/SurugaGoodList', 'views/theme/default/Suruga/SurugaGoodList', '', { title: '骏河屋详情页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/SurugaList', 'views/theme/default/Suruga/SurugaList', '', { title: '骏河屋列表页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),

  route('/selfSupportShopping', 'views/theme/default/selfShopping/selfSupportShopping', '', { title: '自营商城', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/selfGoodDetail', 'views/theme/default/selfShopping/selfGoodDetail', '', { title: '自营商城详情', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/selfGoodsList', 'views/theme/default/selfShopping/selfGoodsList', '', { title: '自营商城列表', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),


]
// 自动加载路由信息
if(lastaccessfile==""){ 
  let auto_load_uri=autoload_index_route('index');
  if(auto_load_uri){
    indexRouterlist[indexRouterlist.length]=auto_load_uri;
  }
}

const publicRouterlist = [
  route('/sign-up', 'views/theme/default/public/SignUp', 'signUp', { title: '注册', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: false }),
  route('/sign-in', 'views/theme/default/public/SignIn', 'signIn', { title: '登录', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: false }),
  route('/forget-pwd', 'views/theme/default/public/ForgetPwd', 'forgetPwd', { title: '忘记密码', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: false }),
]
if(lastaccessfile==""){ 
  let auto_load_uri=autoload_index_route('public');
  if(auto_load_uri){
    publicRouterlist[publicRouterlist.length]=auto_load_uri;
  }
}
// 个人中心子级 prediction-list
const personRouterlist = [
  route('/user', 'views/theme/default/profile/ViewProfile', '', { title: '个人中心', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/edit-user', 'views/theme/default/profile/editUser', '', { title: '修改个人资料', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/real-name', 'views/theme/default/profile/realName', '', { title: '身份证验证', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/billing-details', 'views/theme/default/profile/billingDetails', '', { title: '账单明细', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/my-withdraw', 'views/theme/default/profile/mywithdraw', '', { title: '我的提现', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/After-sales', 'views/theme/default/Orders/After-sales', '', { title: '雅虎-竞拍中', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/add-address', 'views/theme/default/address/AddAddress', '', { title: '添加地址', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/bond-money', 'views/theme/default/user/bondMoney', '', { title: '雅虎-保证金', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/message-center', 'views/theme/default/user/messageCenter', '', { title: '消息中心', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/top-up', 'views/theme/default/user/topUp', '', { title: '账户充值', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/goods-collect', 'views/theme/default/user/goodsCollect', '', { title: '收藏列表', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/keep-order', 'views/theme/default/Orders/keepOrder', '', { title: '雅虎-预约出价', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/jp-order-success', 'views/theme/default/Orders/jpOrderSuccess', '', { title: '雅虎-出价成功', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/jp-order-defeated', 'views/theme/default/Orders/jpOrderDefeated', '', { title: '雅虎-出价失败', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/bargain-order', 'views/theme/default/Orders/bargainOrder', '', { title: '议价订单', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/myMessage', 'views/theme/default/Orders/myMessage', '', { title: '我的留言', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-list', 'views/theme/default/Orders/OrderList', 'OrderList', { title: '代购订单列表', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-details', 'views/theme/default/Orders/OrderDetails', 'OrderDetails', { title: '代购订单详情', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-pack', 'views/theme/default/Orders/Orderpack', '', { title: '订单待打包', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-list-pack', 'views/theme/default/Orders/OrderListPack', 'OrderListPack', { title: '转运包裹', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/parcel-Claim', 'views/theme/default/Orders/parcelClaim', 'parcelClaim', { title: '包裹认领', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-pack-details', 'views/theme/default/Orders/OrderPackDetails', 'OrderPackDetails', { title: '转运包裹详情', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/AUCNET-orderlist', 'views/theme/default/channel/AUCNET/orderlist', '', { title: 'auc订单列表', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/AUCNET-bondlist', 'views/theme/default/channel/AUCNET/bondlist', '', { title: 'auc保证金', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/my-coupon', 'views/theme/default/Orders/myCoupon', 'MyCoupon', { title: '优惠券', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
  // 推广返利
  route('/invitationCode', 'views/theme/default/share/invitationCode', '', { title: '邀请码', ssr: true, pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/money-list', 'views/theme/default/share/moneyList', '', { title: '金额明细', ssr: true, pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/relevancy-user', 'views/theme/default/share/relevancyUser', '', { title: '关联用户', ssr: true, pageWidth: true, keepAlive: false, requireAuth: true }),
]

if(lastaccessfile==""){ 
  let auto_load_uri=autoload_index_route('user');
  if(auto_load_uri){
    personRouterlist[personRouterlist.length]=auto_load_uri;
  }
}
const defaultRouteList = [
  route('/', 'views/theme/default/home/indexParent', 'index', '', indexRouterlist, '/'),
  route('/user', 'views/theme/default/user/userParent', 'person', '', personRouterlist, '/'),
  route('/public', 'views/theme/default/public/publicParent', 'public', '', publicRouterlist, '/'),
  route('/signup-Center', 'views/customization/esenmart.zzqss.shop/pages/public/signupCenter', 'signupCenter', { title: '注册成功', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: false }),
  route('/share', 'views/theme/default/home/shareParent', 'share', { title: '推广联盟', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: false })
]

export default {
  defaultRouteList
}
