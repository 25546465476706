<template>
  <div>
   <router-view />
    <v-overlay
    opacity="0.55"
     :value="overlay"
   >
       <img src="@/assets/images/loading/loading.gif" alt="">
   </v-overlay>
  </div>
 
</template>

<script>
import { now } from 'lodash';

export default {
  name: "App",
  data() {
    return {
      config_:this.$store.state.config,
      overlay:this.$store.state.allOverlay,
      setting: {
        title: this.$store.state.config.topTitle.value?this.$store.state.config.topTitle.value:'',
        keywords: this.$store.state.config.share_title?this.$store.state.config.share_title.value:'',
        image: this.$store.state.config.share_image?this.$store.state.config.share_image.value:'',
        description: this.$store.state.config.share_title?this.$store.state.config.share_title.value:'',
      },
    };
  },
  watch:{
    '$store.state.allOverlay'(val,oval){
      this.overlay=val;
    }
  },
  metaInfo() {
    return {
      title: this.setting.title,
      meta: [
        { name: "keywords", content: this.setting.keywords },
        { name: "description", content: this.setting.description },
        { property: "og:description", content: this.setting.description },
        { property: "og:title", content: this.setting.title },
        { property: "og:image", content: this.setting.image },
        { property: "og:site_name", content: window.location.host },
        { property: "og:url", content: window.location.href },
      ],
      link: [
        {
          rel: "canonical",
          href: window.location.href,
        },
      ],
    };
  },
  mounted(){
    this.changeIco(this.config_.topIcon.value);
    this.isMobile();
  },
  methods: {
    changeIco(href){
      let favicon = document.querySelector('link[rel="icon"]');
      favicon.href=href;
      if(this.$store.state.webHost=='gobuy.zzqss.shop'){
        this.$store.commit('setgoodsPriceUnit','日元')
      }
      if(this.$store.state.webHost=='bidbuy.zzqss.shop'){
        let nullImg = require('@/assets/images/detail/bidbuy_null.png');
        this.$store.commit('setlazySrcImg',nullImg)
			}
      if(this.$store.state.webHost=='riya.zzqss.shop'){
        let nullImg = require('@/assets/images/detail/riya_null.png');
        this.$store.commit('setlazySrcImg',nullImg)
			}
      if(this.$store.state.webHost=='hokutosei.zzqss.shop'){
        let nullImg = require('@/assets/images/detail/img_null1.png');
        this.$store.commit('setlazySrcImg',nullImg)
      }else if(this.$store.state.router_domain_url=='esenmart.zzqss.shop'){
        let nullImg = require('@/assets/images/detail/nullImg.png');
        this.$store.commit('setlazySrcImg',nullImg)
      }
      if(this.$store.state.webHost=='wakuwakubuy.zzqss.shop'){
        this.$store.commit('setRate',true)
        let nullImg = require('@/assets/images/detail/waku_null.png');
        this.$store.commit('setlazySrcImg',nullImg)
      }
      if(this.$store.state.webHost=='croxx.zzqss.shop'){
        let nullImg = require('@/assets/images/detail/croxx_null.png');
        this.$store.commit('setlazySrcImg',nullImg)
      }
      if(this.$store.state.webHost=='daike.zzqss.shop'){
        let nullImg = require('@/assets/images/detail/dake_null.png');
        this.$store.commit('setlazySrcImg',nullImg)
      }
      if(this.$store.state.webHost=='jpware.zzqss.shop'){
          let nullImg = require('@/assets/images/detail/jpware.png');
        this.$store.commit('setlazySrcImg',nullImg)
      }
      if(this.$store.state.webHost=='jpt-house.zzqss.shop'){
          let nullImg = require('@/assets/images/detail/jpt_null.png');
        this.$store.commit('setlazySrcImg',nullImg)
      }
    },
      // 判断时手机端还是pc端 
    isMobile() {

      let isSafari =this.$store.state.isSafari;
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)

      let nowUrl = window.location.host;
      if (nowUrl.includes('www.')) {
        nowUrl = nowUrl.replace('www.', ''); 
      }else{
        nowUrl = this.$store.state.localDomain
      }
      let url_ ='';
      if (this.$store.state.localDomain=='yueyanggou.net') {
        url_='https://m.'+nowUrl+'/#/';
      }
      else if (this.$store.state.localDomain=='jpweb.zzqss.shop') {
        url_='http://y.'+nowUrl+'/#/';
      }
      else if (this.$store.state.localDomain=='jp.moximoxijp.com') {
        url_='http://m.moximoxijp.com'+'/#/';
      } 
      else if (this.$store.state.localDomain=='daipai.daikei-tenso.com') {
        url_='https://m.daikei-tenso.com'+'/#/';
      }
      else if (this.$store.state.localDomain=='51mai.jp' || this.$store.state.localDomain=='51maijp.com') {
        url_='https://check.51mai.jp'+'/#/';
      }
      else{
        url_='http://m.'+nowUrl+'/#/';
      }
      if (flag!=null || isSafari) {
          if (this.$store.state.localDomain=='gobuy.jp' || this.$store.state.localDomain=='www.gobuy.jp' || this.$store.state.localDomain=='japan51buy.com') {
            return
          }
          else{
            if (flag === null && isSafari) {
              return  false
            }
            window.location.replace(url_, "_blank");
          }
      }
      return flag
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]); return null;
    },
  },
};
</script>

<style lang="scss"></style>
