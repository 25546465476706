import store from '@/store/index.js'
const router_domain_url = store.state.router_domain_url

// 异步请求处理
/**
 *
 * @param {路径} path
 * @param {组件路径} file
 * @param {别名，没有传null } name
 * @param {说明，没有就传{} } meta
 * @param {子级，没有子级可以不传} children
 */
function route(path, file, name, meta, children, redirect) {
  return {
    exact: true,
    path,
    name,
    meta,
    children,
    redirect,
    component: () => import(`@/views/customization/${router_domain_url}/pages/${file}`)
  }
}
const indexRouterlist = [
   route('/', 'home/index', 'homeindex', { title: '首页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
   route('/Home', 'home/Home', 'homeHome', { title: '首页', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
   route('/order-pack-pay', 'Orders/OrderpackPay', 'OrderpackPay', { title: '订单打包', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
   route('/order-pay', 'Orders/orederPay', 'orederPay', { title: '支付订单', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
   route('/advance-order', 'home/advanceorder', 'advance-order', { title: '预报下单', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
   route('/checkout-alternative', 'shop/CheckoutAlternative', 'CheckoutAlternative', { title: '提交订单', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
   route('/for-pack-pay', 'Orders/ForPackPay', 'ForPackPay', { title: '支付国际运费', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: false }),
   route('/cart', 'shop/Cart', 'Cart', { title: '购物车', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
]
const personRouterlist = [
  route('/order-list-pack', 'Orders/OrderListPack', 'OrderListPack', { title: '转运包裹', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/After-sales', 'Orders/After-sales', 'After-sales', { title: '雅虎-竞拍中', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/user', 'profile/ViewProfile', 'user', { title: '个人中心', ssr: true,  pageWidth: true, keepAlive: true, requireAuth: true }),
  route('/goods-collect', 'user/goodsCollect', '', { title: '收藏列表', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/top-up', 'user/topUp', '', { title: '账户充值', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/real-name', 'profile/realName', '', { title: '身份证验证', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/add-address', 'address/AddAddress', '', { title: '添加地址', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-list', 'Orders/OrderList', 'OrderList', { title: '代购订单列表', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-details', 'Orders/OrderDetails', 'OrderDetails', { title: '代购订单详情', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
  route('/order-pack-details', 'Orders/OrderPackDetails', 'OrderPackDetails', { title: '转运包裹详情', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: true }),
]
const publicRouterlist = [
  route('/sign-up', 'public/SignUp', 'signUp', { title: '注册', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: false }),
  route('/forget-pwd', 'public/ForgetPwd', 'forgetPwd', { title: '忘记密码', ssr: true,  pageWidth: true, keepAlive: false, requireAuth: false }),
]
export default {
  indexRouterlist: indexRouterlist,
  personRouterlist:personRouterlist,
  publicRouterlist:publicRouterlist,
}
